import { Button, primary, Tooltip } from '@phoenix/all';
import DownCaratIcon from 'phoenix-icons/dist/DownCaratIcon.js';
import RightCaratIcon from 'phoenix-icons/dist/RightCaratIcon.js';
import React, { ReactElement, useEffect, useState, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { IDateRangeState } from '../../../data-flow/dateRange/IDateRangeState';
import { periodsSelector } from '../../../data-flow/dateRange/selectors/periodsSelector';
import { stepsSelector } from '../../../data-flow/dateRange/selectors/stepsSelector';
import { stepUnitSelector } from '../../../data-flow/dateRange/selectors/stepUnitSelector';
import {
    IWorkSchedulingCombinedState,
    IWorkSchedulingDispatchProp,
} from '../../../data-flow/types';
import { localizationClient } from '../../../constants/LocalizationClientFactory';

import { IUnassignedTasksProjects } from '../../../data-flow/data/IDataState';
import { unassignedWorkHeightSelector } from '../../../data-flow/tableSizes/selectors/unassignedWorkHeightSelector';

import { projectGroupingModeSelector } from '../../../data-flow/settings/settingsSelector';
import actionChain from '../../../data-flow/higher-order-reducers/actionChain';
import { arrowCollapsedStateActions, arrowExpandedStateActions } from '../utils';
import changeUnassignedAllNodesArrowState from '../../../data-flow/data/unassignedDataActions/changeUnassignedTasksNodesArrowState';
import { getUnassignedTasksProjectsSelector } from '../../../data-flow/data/selectors/unassignedTasksProjectsSelectors/unassignedTasksProjectsSelectors';
import {
    getLoadUnassignedTasksIssuesLimit,
    sectionsHeaderDataID,
} from '../../../constants/dataConstatnts';
import { arrowButtonCollapseAndExpand } from './TableHeaderStyles';

interface ITableHeaderUnassignedButtonProps extends IWorkSchedulingDispatchProp {
    dataIDs: string[];
    isButtonEnable: boolean;
}

interface ITableHeaderUnassignedButtonStateProps
    extends Pick<IDateRangeState, 'steps'>,
        Pick<IDateRangeState, 'stepUnit'>,
        Pick<IDateRangeState, 'periods'> {
    unassignedWorkHeight: number;
    unassignedTasksProjects: IUnassignedTasksProjects;
    projectGroupingMode: boolean;
}

let taskCount = 0;
export const TableHeaderUnassignedArrowButtonComponent = (
    props: ITableHeaderUnassignedButtonProps & ITableHeaderUnassignedButtonStateProps
): ReactElement => {
    const {
        unassignedWorkHeight,
        projectGroupingMode,
        unassignedTasksProjects,
        dataIDs,
        dispatch,
        isButtonEnable,
    } = props;
    const dataIdsWithoutLoadingAndHeader = dataIDs.filter(
        (id) => id !== '_loading' && !id.includes(sectionsHeaderDataID)
    );

    const [isAnyExpanded, setIsAnyExpanded] = useState(false);
    const [isAllExpanded, setIsAllExpanded] = useState(false);
    const unassignedTasksProjectsIds = useMemo(() => {
        return Object.keys(unassignedTasksProjects).filter(
            (projectID) => unassignedTasksProjects[projectID].nodes.length
        );
    }, [unassignedTasksProjects]);

    const buttonColor = primary.gray(isButtonEnable ? 700 : 300);

    useEffect(() => {
        if (dataIdsWithoutLoadingAndHeader.length > unassignedTasksProjectsIds.length) {
            setIsAnyExpanded(true);
        } else {
            setIsAnyExpanded(false);
        }
    }, [dataIdsWithoutLoadingAndHeader.length, unassignedTasksProjectsIds.length]);

    const handleClick = useCallback((): void => {
        const actions: any[] = [changeUnassignedAllNodesArrowState(isAnyExpanded)];
        unassignedTasksProjectsIds.forEach((projectID) => {
            if (isAnyExpanded) {
                actions.push(
                    ...arrowExpandedStateActions(
                        projectID,
                        unassignedWorkHeight,
                        projectGroupingMode
                    )
                );
                setIsAllExpanded(false);
            } else {
                actions.push(
                    ...arrowCollapsedStateActions(
                        projectID,
                        unassignedTasksProjects[projectID].nodes,
                        unassignedWorkHeight,
                        projectGroupingMode
                    )
                );
            }
            setIsAllExpanded(true);
        });

        dispatch(actionChain(actions));
    }, [
        dispatch,
        isAnyExpanded,
        projectGroupingMode,
        unassignedTasksProjects,
        unassignedTasksProjectsIds,
        unassignedWorkHeight,
    ]);

    useEffect(() => {
        const isFirstLoad =
            unassignedTasksProjectsIds.length <= getLoadUnassignedTasksIssuesLimit();

        if (
            isAllExpanded &&
            !isFirstLoad &&
            unassignedTasksProjectsIds.length > taskCount &&
            unassignedTasksProjectsIds.length > getLoadUnassignedTasksIssuesLimit()
        ) {
            if (dataIDs.includes('_loading')) {
                return;
            }
            const expandedTasksCount = taskCount || taskCount + getLoadUnassignedTasksIssuesLimit();
            const actions: any[] = [changeUnassignedAllNodesArrowState(false, expandedTasksCount)];
            for (let id = expandedTasksCount; id < unassignedTasksProjectsIds.length; id++) {
                actions.push(
                    ...arrowCollapsedStateActions(
                        unassignedTasksProjectsIds[id],
                        unassignedTasksProjects[unassignedTasksProjectsIds[id]].nodes,
                        unassignedWorkHeight,
                        projectGroupingMode
                    )
                );
            }
            taskCount = unassignedTasksProjectsIds.length;
            dispatch(actionChain(actions));
        }
    }, [
        dataIDs,
        dispatch,
        isAllExpanded,
        projectGroupingMode,
        unassignedTasksProjects,
        unassignedTasksProjectsIds,
        unassignedWorkHeight,
    ]);

    const tooltipBtnMsgKey = isAnyExpanded
        ? {
              messageKey: 'workloadbalancer.collapseProjects.unassigned.section.tooltip',
              fallBack: 'Collapse projects',
          }
        : {
              messageKey: 'workloadbalancer.expandProjects.unassigned.section.tooltip',
              fallBack: 'Expand projects',
          };

    return (
        <>
            <Tooltip
                content={localizationClient.getTextSync(
                    tooltipBtnMsgKey.messageKey,
                    tooltipBtnMsgKey.fallBack
                )}
                className={arrowButtonCollapseAndExpand}
            >
                <Button
                    text
                    onClick={handleClick}
                    data-testid={
                        isAnyExpanded
                            ? 'unassigned_collapseAll_button'
                            : 'unassigned_expandAll_button'
                    }
                    disabled={!isButtonEnable}
                >
                    {isAnyExpanded ? (
                        <DownCaratIcon color={buttonColor} height={16} width={16} />
                    ) : (
                        <RightCaratIcon color={buttonColor} height={16} width={16} />
                    )}
                </Button>
            </Tooltip>
        </>
    );
};

const mapStateToProps = (
    state: IWorkSchedulingCombinedState
): ITableHeaderUnassignedButtonStateProps => ({
    unassignedWorkHeight: unassignedWorkHeightSelector(state),
    steps: stepsSelector(state),
    stepUnit: stepUnitSelector(state),
    periods: periodsSelector(state),
    unassignedTasksProjects: getUnassignedTasksProjectsSelector(state),
    projectGroupingMode: projectGroupingModeSelector(state),
});

export const TableHeaderUnassignedArrowButton = connect(mapStateToProps)(
    TableHeaderUnassignedArrowButtonComponent
);
