import { DurationTypes } from 'workfront-enums';
import { isEnabled } from '../contexts/featureToggleContext';

export const LOAD_USERS_LIMIT = 15;
export const LOAD_UNASSIGNED_TASKS_ISSUES_LIMIT = 20;
export const LOAD_UNASSIGNED_TASKS_ISSUES_LIMIT_INCREASED = 50;

// todo: remove this function and keep only increased limit after toggle removal.
//  Also do changes in the code where this function is used
export const getLoadUnassignedTasksIssuesLimit = () => {
    return isEnabled('unassignedProjectsBatchCall')
        ? LOAD_UNASSIGNED_TASKS_ISSUES_LIMIT_INCREASED
        : LOAD_UNASSIGNED_TASKS_ISSUES_LIMIT;
};

export const LOAD_TASKS_ISSUES_LIMIT = 10;
export const LOAD_PROJECTS_LIMIT = 10;
export const DAYS_COUNT_IN_WEEK = 7;

export const LOAD_ASSIGNMENTS_LIMIT = 2000;
export const USER_FIELDS = ['name', 'roleID', 'userRoles:role:name', 'scheduleID', 'avatarDate'];
export const PROJECT_FIELDS = ['ID', 'name', 'scheduleID', 'groupID', 'status'];

export const ISSUES_FIELDS = [
    'opTask:plannedStartDate',
    'opTask:plannedCompletionDate',
    'opTask:projectID',
    'opTask:permissions',
    'opTask:actualStartDate',
    'opTask:actualCompletionDate',
    'opTask:project:status',
    'opTask:project:name',
    'opTask:workRequired',
    'opTask:status',
    'opTask:canStart',
    'opTask:assignments:status',
    'opTask:assignments:assignedToID',
];

export const TASKS_FIELDS = [
    'task:plannedStartDate',
    'task:plannedCompletionDate',
    'task:project:groupID',
    'task:projectID',
    'task:project:status',
    'task:durationType',
    'task:taskNumber',
    'task:permissions',
    'task:projectedStartDate',
    'task:projectedCompletionDate',
    'task:actualCompletionDate',
    'task:project:status',
    'task:project:name',
    'task:status',
    'task:isWorkRequiredLocked',
    'task:isDurationLocked',
    'task:workRequired',
    'task:duration',
    'task:durationType',
    'task:durationUnit',
    'task:recurrenceRuleID',
    'task:parent',
    'task:predecessors:predecessor',
    'task:canStart',
    'task:assignments:status',
    'task:assignments:assignedToID',
];

export const ASSIGNMENT_FIELDS = ['assignedToID', 'workRequired'];

export const BULK_ASSIGNMENTS_FIELDS = [
    'assignedToID',
    'assignedTo',
    'workRequired',
    'task',
    'opTask',
    'project:status',
    'task:status',
    'roleID',
    'role',
    'assignedTo:userRoles:role:name',
    'assignedTo:avatarDate',
];

export const BULK_ASSIGNMENT_OTHER_RECOMMENDED_FIELDS = [
    'ID',
    'accessLevelID',
    'avatarDate',
    'emailAddr',
    'firstName',
    'lastName',
    'licenseType',
    'objCode',
    'role',
    'roles',
    'userRoles:role:name',
];

export const UNASSIGNED_ASSIGNMENT_FIELDS = [
    'plannedStartDate',
    'plannedCompletionDate',
    'projectID',
    'taskNumber',
    'permissions',
    'assignments:ID',
    'assignments:assignedToID',
    'assignments:roleID',
    // TODO: clean up, this is not used anymore
    'workPerDayList',
    'project:scheduleID',
    'project:groupID',
    'project:status',
    'project:name',
    'parent',
    'predecessors:predecessor',
    'isWorkRequiredLocked',
    'isDurationLocked',
    'workRequired',
    'duration',
    'durationType',
    'durationUnit',
    'recurrenceRuleID',
];

export const UNASSIGNED_ISSUES_FIELDS = [
    'plannedStartDate',
    'plannedCompletionDate',
    'projectID',
    'permissions',
    'assignments:ID',
    'assignments:assignedToID',
    'assignments:roleID',
    'workPerDayList',
    'project:scheduleID',
    'project:groupID',
    'project:status',
    'project:name',
    'parent',
    'workRequired',
];

export const USERS_RESERVED_TIMES_FIELDS = ['ID', 'userID', 'startDate', 'endDate'];
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT_TIMELINE = 'MM-DD-YYYY';
export const DATE_FORMAT_MONTH = 'MMMM YYYY';
export const DATE_FORMAT_MONTH_SHORT = 'MMM YYYY';
export const DATE_FORMAT_YEAR = 'YYYY';
export const DATE_FORMAT_MONTH_ONLY = 'MMM';
export const DATE_FORMAT_MONTH_ONLY_SHORT = 'MM';
export const HOUR_FORMAT = 'HH:mm';
export const START_HOUR = '00:00';
export const END_HOUR = '23:59';
export const PERIOD_MODE_FOR_NEXT_PREV = 1;

export const dateFormats = {
    day: DATE_FORMAT_TIMELINE,
    month: DATE_FORMAT_MONTH,
};

export const usersTasksSortObject = [
    {
        prop: 'plannedStartDate',
        secondProp: 'projectedStartDate',
        type: 'date',
    },
    {
        prop: 'plannedCompletionDate',
        secondProp: 'projectedCompletionDate',
        type: 'date',
    },
    {
        prop: 'name',
        type: 'string',
    },
];

export const unassignedTasksSortObject = [
    {
        prop: 'plannedStartDate',
        type: 'date',
    },
    {
        prop: 'plannedCompletionDate',
        type: 'date',
    },
    {
        prop: 'name',
        type: 'string',
    },
];

export const objectProjectedDates = {
    'task:': {
        startDate: 'projectedStartDate',
        completionDate: 'projectedCompletionDate',
    },

    'opTask:': {
        startDate: 'actualStartDate',
        completionDate: 'actualCompletionDate',
    },
};

export const osTypes = {
    mac: 'Mac',
    iOS: 'iOS',
    windows: 'Windows',
    android: 'Android',
    linux: 'Linux',
};

export enum DurationTypeLabels {
    SIMPLE = 'Simple',
    CALCULATED_ASSIGNMENT = 'Calculated Assignment',
    CALCULATED_WORK = 'Calculated Work',
    EFFORT_DRIVEN = 'Effort Driven',
}

export const durationTypes = [
    {
        value: DurationTypes.SIMPLE.value,
        label: DurationTypeLabels.SIMPLE,
    },
    {
        value: DurationTypes.CALCULATED_ASSIGNMENT.value,
        label: DurationTypeLabels.CALCULATED_ASSIGNMENT,
    },
    {
        value: DurationTypes.CALCULATED_WORK.value,
        label: DurationTypeLabels.CALCULATED_WORK,
    },
    {
        value: DurationTypes.EFFORT_DRIVEN.value,
        label: DurationTypeLabels.EFFORT_DRIVEN,
    },
];

export const durationUnits = [
    { label: 'Minutes', value: 'M' },
    { label: 'Hours', value: 'H' },
    { label: 'Days', value: 'D' },
    { label: 'Weeks', value: 'W' },
    { label: 'Months', value: 'T' },
    { label: 'Elapsed Minutes', value: 'EM' },
    { label: 'Elapsed Hours', value: 'EH' },
    { label: 'Elapsed Days', value: 'ED' },
    { label: 'Elapsed Weeks', value: 'EW' },
    { label: 'Elapsed Months', value: 'ET' },
];

export const WIDGET_BREAKPOINT = 1280;
export const BIG_WIDGET_SIZE = 472;
export const SMALL_WIDGET_SIZE = 360;
export const WIDGET_RIGHT_FOR_QS = 0;
export const WIDGET_HEIGHT_ADDITION_QS = 18;

export const PROJECT_TASK_TOOLTIP_DELAY = 700;

export const chartComponentIndex = 1;
export const fullTimeOffTopBorderIndex = chartComponentIndex + 2;

export const wpdCallMaxCount = 2;

export const unassignedSectionSliceIndex = 2;
export const assignedSectionSliceIndex = 1;
export const sectionsHeaderDataID = 'headerRow';
export const assignedSectionHeaderDataID = [sectionsHeaderDataID];
export const unassignedSectionHeaderDataID = [
    `${sectionsHeaderDataID}-1`,
    `${sectionsHeaderDataID}-2`,
];

export const OLD_LICENSE_TYPE = 1;
