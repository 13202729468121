import React from 'react'
import { SetupLayout, SetupLayoutContent } from '@setup/components'
import CheckboxesExtraLargeHeroIcon from 'phoenix-icons/dist/CheckboxesExtraLargeHeroIcon.js'
import { messageForHook } from '../constants/messages'
import { useLocalization } from '../utils/l10'

const CONTEXT_SENSITIVE_HELP_PAGE_ID = 'csh-list-conflict-rules'

interface IConflictRulesSetupLayoutProps {
  children: React.ReactNode
}

export const ConflictRulesSetupLayout: React.FC<IConflictRulesSetupLayoutProps> = (props) => {
  const [title] = useLocalization(...messageForHook['view.companyconflictrule.list'])
  const { children } = props

  return (
    <SetupLayout
      contextSensitiveHelpPageID={CONTEXT_SENSITIVE_HELP_PAGE_ID}
      headerIcon={<CheckboxesExtraLargeHeroIcon />}
      title={title}
    >
      <SetupLayoutContent>{children}</SetupLayoutContent>
    </SetupLayout>
  )
}
