import { WfetchCache } from './caching/WfetchCache.js'
import { getCacheKey } from './caching/getCacheKey.js'
import { getWorkfrontRootApiUrl } from './configuration'
import getFetchOptions from './getFetchOptions.js'
import {
  reauthenticateIms,
  redirectToLogin,
  shouldRedirectToLogin,
} from './redirectToLogin.js'
import {
  isQssUiDataUrl,
  isRedRockUrl,
  isSpringControllerUrl,
  isStreamApiUrl,
} from './requestTypes.js'
import { getInitialTrackingObject, trackRequest } from './trackingFetch.js'
import {
  getEimInstanceId,
  getTimeOnPage,
  getUrlWithOptionalPublicToken,
  unwrapResponse,
} from './utilities.js'

/**
 * @typedef {Omit<RequestInit, 'body'> & {body?: BodyInit | Object}} CustomRequestInit
 * @property {BodyInit|Object} [body]
 */

/**
 * Wrapped fetch call that can communicate with the Stream API,
 * Spring Controller and other endpoints.
 * Redirects to login if user is not authenticated.
 * @param {string} url   endpoint url to make the request to
 * @param {CustomRequestInit} [fetchOptions]   fetch-compatible options object
 * @param {object} [workfrontOptions]   Workfront-specific options object
 * @returns {Promise<String|Object> | never}   Unwraps response based on request type (json or text)
 */
export async function wfetch(url, fetchOptions = {}, workfrontOptions = {}) {
  const workfrontRequest = new WorkfrontRequest(
    url,
    fetchOptions,
    workfrontOptions,
  )

  return workfrontRequest.send()
}

export class WorkfrontRequest {
  static responsePassThrough = (response) => response
  static successfulRequestsCount = 0
  static lastSuccessfulRequestTime = 0

  constructor(url, fetchOptions, workfrontOptions) {
    this.url = url
    this.fullUrl = getUrlWithOptionalPublicToken(getWorkfrontRootApiUrl() + url)
    const tracking = getInitialTrackingObject(fetchOptions)

    this.trackRequest = () => {
      trackRequest(url, tracking)
    }
    this.mergedFetchOptions = getFetchOptions(url, fetchOptions, tracking)

    let wfOptionsUsingInitialCall

    if (window.qsBootstrapCalls?.[url]) {
      // purposefully not passing in workfrontOptions here
      // the initial calls in quicksilver-server don't have those options
      // and we need to be able to compare the wfetch call to the initial call
      const cacheKey = getCacheKey(url, this.mergedFetchOptions)

      if (Array.isArray(window.qsBootstrapCalls[url])) {
        for (const prefetchInstance of window.qsBootstrapCalls[url]) {
          if (prefetchInstance.cacheKey === cacheKey) {
            wfOptionsUsingInitialCall = {
              ...workfrontOptions,
              initialRequest: prefetchInstance.fetchPromise,
              timeToExpiration: workfrontOptions.timeToExpiration ?? 5e3,
              deleteAfterExpiration: () => {
                window.qsBootstrapCalls[url] = window.qsBootstrapCalls[
                  url
                ].filter((p) => p !== prefetchInstance)
              },
            }
          }
        }
      } else {
        if (window.qsBootstrapCalls[url].cacheKey === cacheKey) {
          wfOptionsUsingInitialCall = {
            ...workfrontOptions,
            initialRequest: window.qsBootstrapCalls[url],
            timeToExpiration: workfrontOptions.timeToExpiration ?? 5e3,
            deleteAfterExpiration: () => delete window.qsBootstrapCalls[url],
          }
        }
      }
    }

    this.cache = new WfetchCache(
      this.fullUrl,
      this.mergedFetchOptions,
      wfOptionsUsingInitialCall || workfrontOptions,
    )

    this.shouldRedirectResponseToLogin = (response) =>
      !workfrontOptions.preventAutoRedirect && shouldRedirectToLogin(response)
    this.handleErrorResponse = workfrontOptions.handleErrorResponse
    this.handleOkResponse = workfrontOptions.handleOkResponse
  }

  async send() {
    const cachedData = this.cache.getCachedData()
    if (cachedData) {
      return cachedData
    }

    try {
      let response = await this.getCachedOrNewResponse()
      this.trackRequest()

      if (this.shouldRedirectResponseToLogin(response)) {
        response = await this.retryBeforeLoggingOut()
      }

      if (response.ok) {
        WorkfrontRequest.successfulRequestsCount++
        WorkfrontRequest.lastSuccessfulRequestTime = getTimeOnPage()

        return await this.getSuccessHandler()(response)
      }

      if (this.handleErrorResponse) {
        return this.handleErrorResponse(response)
      }

      return await this.getErrorHandler()(response)
    } catch (error) {
      throw error instanceof Error ? error : new Error(error)
    }
  }

  getCachedOrNewResponse() {
    return this.cache.getCachedResponse() || this.fetch()
  }

  fetch() {
    return fetch(this.fullUrl, this.mergedFetchOptions)
  }

  async retryBeforeLoggingOut() {
    let retriedResponse

    // this first retry is attempting to diagnose if there was an intermittent
    // problem in the target service, a.k.a. fluke or race condition
    const [
      response,
      stillLoggedInToWorkfront,
      { logRumEvent },
      { getIsUnifiedShellEnabled },
    ] = await Promise.all([
      this.fetch(),
      getIsLoggedInToWorkfront(),
      System.import('@wf-mfe/logger'),
      System.import('@wf-mfe/unified-shell-bootstrapper'),
    ])

    retriedResponse = response

    const isUnifiedShellEnabled = await getIsUnifiedShellEnabled()

    const rumActionContext = {
      isUnifiedShellEnabled,
      lastSuccessfulRequestTime: WorkfrontRequest.lastSuccessfulRequestTime,
      stillLoggedInToWorkfront,
      reauthenticatedRetrySucceeded: undefined,
      retrySucceeded: retriedResponse.ok,
      successfulRequestsCount: WorkfrontRequest.successfulRequestsCount,
      timeOnPage: getTimeOnPage(),
      url: this.fullUrl,
    }

    if (isUnifiedShellEnabled) {
      rumActionContext.eimInstanceId = getEimInstanceId()

      if (shouldRedirectToLogin(retriedResponse)) {
        const imsReauthenticated = await reauthenticateIms()
        rumActionContext.imsReauthenticated = imsReauthenticated

        if (imsReauthenticated) {
          retriedResponse = await this.fetch()
          rumActionContext.reauthenticatedRetrySucceeded = retriedResponse.ok
        }
      }
    }

    logRumEvent('wfetch_logout', rumActionContext)

    if (shouldRedirectToLogin(retriedResponse)) {
      await redirectToLogin(this.fullUrl)
    }

    return retriedResponse
  }

  getErrorHandler() {
    return async (response) => {
      let message = response.statusText
      let errorData

      try {
        errorData = await response.json()
        message = errorData?.error?.message
      } catch (error) {
        console.error(error)
      }

      const responseError = new Error(
        `\n\t${response.status}: ${message}\n\t${this.url}`,
      )

      responseError.response = response
      responseError.errorData = errorData
      responseError.status = response.status

      throw responseError
    }
  }

  getSuccessHandler() {
    let handleResponseFunction = this.handleOkResponse

    if (handleResponseFunction === undefined && isRedRockUrl(this.url)) {
      handleResponseFunction = this.unwrapRedRockResponse
    }

    if (handleResponseFunction === undefined) {
      return WorkfrontRequest.responsePassThrough
    }

    return async (response) => {
      const responseData = await handleResponseFunction.call(this, response)

      this.cache.cacheResponseData(responseData)

      return responseData
    }
  }

  async unwrapRedRockResponse(response) {
    const parsedData = await unwrapResponse(response)
    let responseData

    if (isStreamApiUrl(this.url)) {
      if (parsedData.error) {
        throw errorWithTitle(parsedData.error.message, parsedData.error.title)
      }

      responseData = getActualDataForStreamApiRespnse(parsedData)

      if (this.url.includes('/attask/api-internal/batch')) {
        responseData = responseData.map(getActualDataForStreamApiRespnse)
      }
    } else if (isSpringControllerUrl(this.url)) {
      if (parsedData.error) {
        throw errorWithTitle(parsedData.error.message, parsedData.error.title)
      }

      responseData = getActualDataForSpringControllerResponse(parsedData)
    } else if (isQssUiDataUrl(this.url)) {
      if (parsedData.error) {
        throw errorWithTitle(parsedData.error.message, parsedData.error.title)
      }

      responseData = parsedData
    }

    return responseData
  }
}

function getActualDataForStreamApiRespnse(parsedData) {
  return parsedData?.data?.result ?? parsedData?.data ?? parsedData
}

function getActualDataForSpringControllerResponse(parsedData) {
  return parsedData?.data?.data ?? parsedData?.data ?? parsedData
}

function errorWithTitle(message, title) {
  const error = new Error(message)
  error.title = title
  return error
}

async function getIsLoggedInToWorkfront() {
  try {
    const userData = await wfetch(
      '/attask/api-internal/USER/currentUser',
      {},
      { preventAutoRedirect: true, handleErrorResponse: () => {} },
    )
    return userData?.length > 0
  } catch (error) {
    return false
  }
}
