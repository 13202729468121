import { LoadLocalizations, LocalizationProvider } from '@workfront/localize-react';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { PageContentSlot } from '@wf-mfe/layout';
import { useIsTreatmentEnabled } from '@wf-mfe/toggles';
import {
    useCurrentCustomer,
    useCurrentUserField,
    useCurrentUser,
    useCurrentCustomerField,
} from '@wf-mfe/auth';
import { clientFactory } from './constants/LocalizationClientFactory';
import { FeatureToggles } from './contexts/featureToggleContext';
import {
    GlobalPropsContext,
    IGlobalPropsContext,
    IUnchangeableContext,
} from './contexts/globalContexts';
import { configureStore } from './data-flow/configureStore';
import { TWorkSchedulingCombinedStore } from './data-flow/types';
import { LoggerService } from './services/logger/LoggerService';
import { UnchangeableContext } from './contexts/checkContext';
import { currentUserMapper, ICustomer, IUser } from './util/currentUserMapper';
import { createTimezoneCookieIfNotExists } from './util/createTimezoneCookieIfNotExists';
import { ModernWorkContainer } from './ModernWorkContainer';
import { modernDnDContext } from './components/DndWrapper';
import {
    apiRequestMessageKeys,
    filtersMessageKeysForContent,
    getMessageKeys,
    serviceNamesPerKey,
} from './messageKeys';

let store;

export const getStore = (options): TWorkSchedulingCombinedStore => {
    let existingAreaID = '';

    if (store) {
        const state = store.getState();
        existingAreaID = state.areaData.schedulingAreaID;
    }

    if (!store || options.schedulingAreaData?.ID !== existingAreaID) {
        store = configureStore(options);
    }
    return store;
};

export const ModernWorkSchedulingGeneral = (options): JSX.Element | null => {
    const customerCustomQuarters = useCurrentCustomerField('customQuarters:*');
    useCurrentCustomerField('currentLane:*');
    // Because useCurrentUser hook return type is 'WfUserData', which is wrong.
    const currentUser = useCurrentUser() as unknown as IUser;
    const currentCustomer = useCurrentCustomer() as unknown as ICustomer;

    const features = {
        unassignedProjectsBatchCall: useIsTreatmentEnabled('wlb-unassigned-projects-batch-call'),
    };

    // This is necessary for getting user Access levelPermissions
    useCurrentUserField('accessLevel:accessLevelPermissions:*');

    useEffect(() => {
        /**
         * As `store` variable is outside of React context, it is not
         * being Garbage Collected, and old state is being reserved
         * on next component mount.
         * Here we manually set it to `undefined` to make state be created again.
         * */
        return () => {
            store = undefined;
        };
    }, []);

    useEffect(() => {
        createTimezoneCookieIfNotExists();
    }, []);

    if (!currentUser.accessLevel.accessLevelPermissions || !customerCustomQuarters) {
        return null;
    }

    LoggerService.makeInstance();
    const messageKeys = getMessageKeys(options);
    const sharableLink = window.location.href.split('p=')[1];

    const unchangeableContext: IUnchangeableContext = {
        noAccess: options.noAccess,
        currentUser: currentUserMapper(currentUser, currentCustomer),
        switchToOtherView: options.switchToOtherView,
        sharableLink,
    };
    const globalContext: IGlobalPropsContext = {
        minixOpen: options.minixOpen,
        minixClose: options.minixClose,
        minixState: options.minixState,
    };

    return (
        <div id={modernDnDContext}>
            <FeatureToggles features={features}>
                <UnchangeableContext contextValues={unchangeableContext}>
                    <GlobalPropsContext.Provider value={globalContext}>
                        <Provider
                            store={getStore({
                                ...options,
                                currentUser,
                                currentCustomer,
                                context: {
                                    ...globalContext,
                                    ...unchangeableContext,
                                },
                            })}
                        >
                            <LocalizationProvider clientFactory={clientFactory}>
                                <LoadLocalizations
                                    messageKeys={[
                                        ...filtersMessageKeysForContent,
                                        ...apiRequestMessageKeys,
                                        ...messageKeys,
                                        serviceNamesPerKey.filter,
                                    ]}
                                >
                                    <ModernWorkContainer />
                                </LoadLocalizations>
                            </LocalizationProvider>
                        </Provider>
                    </GlobalPropsContext.Provider>
                </UnchangeableContext>
            </FeatureToggles>
        </div>
    );
};

export const ModernWorkSchedulingGeneralContainer = (options): JSX.Element | null => (
    <PageContentSlot>
        <ModernWorkSchedulingGeneral {...options} />
    </PageContentSlot>
);
