import appColors from '../constants/appColors';
import { ChartViewMaxHours, sizes } from '../constants/schedulingTableConstants';
import { UnitOfTime } from '../data-flow/types';
import { getPeriodModesValues } from './periodUtils/periodUtils';
import { convertMinuteToHour } from './utilities';

/** \
 *
 * @param hourPerDay
 * @param workingHours
 * @returns {boolean}
 */
export function isOverAllocated(hourPerDay, workingHours) {
    return convertMinuteToHour(hourPerDay) > convertMinuteToHour(workingHours);
}

/**
 *
 * @param height
 * @param scaledD
 */
export const chartGetY = (height, scaledD) => height - scaledD;

/**
 *
 * @param stepUnit
 */
export const getMaxHoursForChartView = (stepUnit: UnitOfTime): ChartViewMaxHours => {
    const { isDayMode, isWeekMode } = getPeriodModesValues(stepUnit);
    if (isDayMode) {
        return ChartViewMaxHours.MAX_HOURS_DAILY;
    }
    if (isWeekMode) {
        return ChartViewMaxHours.MAX_HOURS_WEEKLY;
    }
    return ChartViewMaxHours.MAX_HOURS_MONTHLY;
};

/**
 *
 * @param workPer
 * @param stepUnit
 */
export const getLimitedWPDForView = (workPer: number, stepUnit: UnitOfTime) => {
    const maxHoursForChartView = getMaxHoursForChartView(stepUnit);
    return workPer > maxHoursForChartView ? maxHoursForChartView : workPer;
};

/**
 *
 * @param workPer
 * @param stepUnit
 */
export const yScale = (workPer: number, stepUnit: UnitOfTime): number => {
    const maxPerPeriod = getMaxHoursForChartView(stepUnit);
    const limitedWorkPerDay = getLimitedWPDForView(workPer, stepUnit);

    const scaledHeight =
        ((sizes.tableRowHeightChartView - sizes.tableGapFromTopChartView) * limitedWorkPerDay) /
        maxPerPeriod;

    return !scaledHeight || scaledHeight <= 0 ? 1 : scaledHeight;
};

const underAllocatedColor = {
    '100': appColors.DBECF9['0.62'],
    '63': appColors.C7E1F6['0.74'],
    '0': appColors.primary_blue_100.hex,
};

const overAllocatedColor = {
    '100': appColors.E06264['0.57'],
    '58': appColors.DC4042['0.71'],
    '0': appColors.overAllocated['0.8'],
};

/**
 *
 * @param isOverAlloc
 */
export const getChartBarGradientColors = (isOverAlloc) =>
    !isOverAlloc ? underAllocatedColor : overAllocatedColor;

export const ALLOCATIONS_OPACITY_LEVELS = {
    high: 0.5,
    low: 0.2,
};

/**
 *
 * @param workPerDay
 * @param availableHour
 */
export const getOverAllocationOpacityLevel = (workPerDay, availableHour) => {
    if (workPerDay > availableHour * 1.25) {
        return ALLOCATIONS_OPACITY_LEVELS.high;
    }

    return ALLOCATIONS_OPACITY_LEVELS.low;
};

export const previousWPDStatuses = {
    equal: 'equal',
    greater: 'greater',
    smaller: 'smaller',
};

/**
 *
 * @param prevWorkPerDay
 * @param workPerDay
 */
export const getPreviousStatus = (prevWorkPerDay, workPerDay) => {
    let previousStatus = previousWPDStatuses.equal;
    if (prevWorkPerDay > workPerDay) {
        previousStatus = previousWPDStatuses.greater;
    } else if (prevWorkPerDay < workPerDay) {
        previousStatus = previousWPDStatuses.smaller;
    }

    return previousStatus;
};
