const messages = {
  'view.companyconflictrule.list': 'Conflict Rules',
}

export type MessageKey = keyof typeof messages
export type MessageTuple = [string, string]
type MessageForHook = Record<MessageKey, MessageTuple>

const messageForHook: MessageForHook = Object.entries(messages).reduce(
  (allMessages, [messageKey, fallback]) => ({
    ...allMessages,
    [messageKey]: [messageKey, fallback],
  }),
  {} as MessageForHook,
)

export { messages, messageForHook }
