import runtime from '@adobe/exc-app';
import { ProjectedProductContext } from '@adobe/exc-app/ims/ImsProfile';
import { nanoid } from 'nanoid';
import { getTreatmentIsEnabled$ } from '@wf-mfe/toggles';
import { getIsInGenStudio } from '@wf-mfe-maestro/navigation';

const WORKFRONT_SERVICE_CODE = 'workfront';
export const generateIntermediaryId = () => nanoid();
export const intermediaryId = generateIntermediaryId();
export const getIntermediaryHeader = (id?: string) => ({
  'X-Intermediary': id || intermediaryId,
});

const getFulfillableDataFromIMSProfile = <T = unknown>(serviceCode: string): T | undefined => {
  const runtimeInstance = runtime();
  const projectedProductContext =
    runtimeInstance.lastConfigurationPayload?.imsInfo?.imsProfile?.projectedProductContext;
  if (projectedProductContext) {
    const foundServices = projectedProductContext.filter(
      (item: ProjectedProductContext) => item.prodCtx.serviceCode === serviceCode
    );
    if (foundServices.length > 1) {
      throw new Error(
        `Multiple product contexts with serviceCode "${serviceCode}" found. Not sure which one to use.`
      );
    } else if (foundServices.length === 1 && foundServices[0].prodCtx?.fulfillable_data) {
      return JSON.parse(foundServices[0].prodCtx.fulfillable_data);
    }
  }
};

const getRegionFromIMSProfile = (): string | undefined =>
  getFulfillableDataFromIMSProfile<{ region: string }>('dma_tartan')?.region;

const getProdCtxCountByServiceCode = (serviceCode: string): number => {
  const runtimeInstance = runtime();
  const projectedProductContext =
    runtimeInstance.lastConfigurationPayload?.imsInfo?.imsProfile?.projectedProductContext;

  const foundServices = projectedProductContext?.filter(
    (item: ProjectedProductContext) => item.prodCtx.serviceCode === serviceCode
  );

  return foundServices?.length || 0;
};

const getWorkfrontSubDomainFromIMSProfile = (): string | undefined => {
  const prodCtxCount = getProdCtxCountByServiceCode(WORKFRONT_SERVICE_CODE);
  if (prodCtxCount === 1) {
    return getFulfillableDataFromIMSProfile<{ subDomain: string }>(WORKFRONT_SERVICE_CODE)
      ?.subDomain;
  }

  return undefined;
};

export const getGatewayHeader = (): object => {
  let workspaceInPlanningEnabled = false;
  getTreatmentIsEnabled$('genstudio-workspace-in-planning').subscribe((isEnabled: boolean) => {
    workspaceInPlanningEnabled = isEnabled;
  });

  const subDomain =
    workspaceInPlanningEnabled && getIsInGenStudio()
      ? getWorkfrontSubDomainFromIMSProfile()
      : undefined;
  if (subDomain) {
    return {
      'x-gw-subdomain': subDomain,
    };
  }

  const region = getRegionFromIMSProfile();
  if (region) {
    return {
      'x-gw-region': region,
    };
  }
  throw new Error('Necessary headers cannot be determined from IMS profile');
};
