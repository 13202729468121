import createUnresolveablePromise from './createUnresolveablePromise.js'
import logger from './logger'
import { getEimInstanceId, getTimeOnPage } from './utilities'

/**
 * Redirects to login page
 */
export async function redirectToLogin(fullUrl) {
  window._REDIRECTING_TO_LOGIN_ = true

  const isUnifiedShellEnabled = await getIsUnifiedShellEnabled()
  const { logRumEvent } = await System.import('@wf-mfe/logger')

  if (isUnifiedShellEnabled) {
    const user = await getExcAppUser()
    window._REDIRECTING_TO_LOGIN_ = false
    logRumEvent('redirectToLogin_unified_shell', {
      eimInstanceId: getEimInstanceId(),
      fullUrl,
      timeOnPagePriorToLogout: getTimeOnPage(),
    })
    window._REDIRECTING_TO_LOGIN_ = true
    user.authExpired()

    return createUnresolveablePromise()
  }

  if (isInWorkfront()) {
    window._REDIRECTING_TO_LOGIN_ = false
    logRumEvent('redirectToLogin_wf', {
      fullUrl,
      timeOnPagePriorToLogout: getTimeOnPage(),
    })
    window._REDIRECTING_TO_LOGIN_ = true
    window.location.assign(
      `${window.location.origin}/qs/login?nextURL=${encodeURIComponent(
        `${window.location.pathname}${window.location.search}`,
      )}`,
    )

    return createUnresolveablePromise()
  }

  logger.warn('Workfront did not redirect to login')
}

export function shouldRedirectToLogin(response) {
  const isPublicPage = !!window?.config?.publicToken

  if (isPublicPage) {
    return false
  }

  const notAuthorized = response.status === 401
  const mustLogin = response.redirected && response.url?.startsWith('/login')

  return notAuthorized || mustLogin
}

const workfrontHostNameRegex = /\.workfront(-dev)?\./
function isInWorkfront() {
  return window.location.hostname.match(workfrontHostNameRegex)
}

async function getIsUnifiedShellEnabled() {
  const { getIsUnifiedShellEnabled } = await System.import(
    '@wf-mfe/unified-shell-bootstrapper',
  )

  return getIsUnifiedShellEnabled()
}

async function getExcAppUser() {
  const { default: user } = await import('@adobe/exc-app/user')

  return user
}

export async function reauthenticateIms() {
  const user = await getExcAppUser()
  const imsToken = await user.get('imsToken')

  const response = await fetch(
    '/authn/authenticate/access_token/ims?refreshable',
    {
      method: 'POST',
      headers: { authorization: `Bearer ${imsToken}` },
    },
  )

  return response.ok
}
