import React, { Suspense, lazy } from 'react'

import { IAdvancedAssignmentsDialogProps } from './AdvancedAssignmentsDialog'

const AdvancedAssignmentsDialogWrapper = lazy(() => import('./AdvancedAssignmentsDialogWrapper'))

export const AdvancedAssignmentsDialogLazy = (props: IAdvancedAssignmentsDialogProps) => (
  <Suspense fallback="">
    <AdvancedAssignmentsDialogWrapper {...props} />
  </Suspense>
)
