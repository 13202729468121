import React from 'react'
import { ConflictRulesList } from './ConflictRulesList'
import { ConflictRulesSetupLayout } from './ConflictRulesSetupLayout'
import { messages } from '../constants/messages'
import { LoadLocalizations } from '../utils/l10'

export const ConflictRulesSetupPage: React.FC = () => {
  return (
    <ConflictRulesSetupLayout>
      <LoadLocalizations messageKeys={[...Object.keys(messages)]}>
        <ConflictRulesList />
      </LoadLocalizations>
    </ConflictRulesSetupLayout>
  )
}
